.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #ffffff;
    text-align: center;
    font-family: 'Arial';
    font-weight: 'normal';
    clear: both;
    margin-top: 0;
    background-color:#5454e9;
    font-size: 10px ;
    height: 40px;
}

.footer-text {
    margin-top: 5px;
}