img {
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px;  /* Rounded border */
    padding: 2px; /* Some padding */
    width: 100px; /* Set a small width */
    height: 150px;
  }
  
  /* Add a hover effect (blue shadow) */
img:hover {
    position:relative;
    /* top:-25px; */
    /* left:-35px; */
    width:300px;
    height:300px;
    display:block;
    /* z-index:999; */
  }

.zoom {
    padding: 50px;
    background-color: green;
    transition: transform .2s; /* Animation */
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
  
.zoom:hover {
    transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }