.html {
    height: 100%;
}

.body {
    margin-top: 65px;
    position: relative;
    height: '100%';
    min-height: 90vh;
    overflow: hidden;
    padding-bottom: 60px;
    padding-top: 2%;
    font-family: 'Arial';
    font-weight: 'normal';
    color: #13178C;
    background-color: white;
}