.side-drawer {
    height: 100%;
    background-color: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out ;
    font-family: 'Arial';
    font-weight: 'normal';
}

.active {
    text-decoration: underline;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer__logo {
    margin: 0;
}


.side-drawer a {
    display: flex;
    flex-direction: column;
    color: #13178C;
    margin: 0.5rem 1.5rem 10px;   
}

.side-drawer a:hover {
    font-size: large;
    text-decoration: none;
}

.side-drawer-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    padding-left: 15px;
}


.side-drawer__footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #5454e9;
    text-align: center;
    font-family: 'Arial';
    font-weight: 'normal';
    clear: both;
    margin-top: 0;
    background-color: #5454e9;
    height: 40px;
}

.side-drawer__header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    color: #5454e9;
    text-align: center;
    font-family: 'Arial';
    font-weight: 'normal';
    clear: both;
    margin-top: 0;
    background-color: #5454e9;
    height: 40px;
}

.follow-us-icons__links {
    margin: 0.5rem 0.5rem 0; 
}

/* @media (min-width: 769px) {
    .side-drawer {
        display: none;
    }
} */